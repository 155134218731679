//
// This file is AUTO-GENERATED by protoc-gen-ts.
// Do not modify it manually.
///
import api, { IStreamMessage, IServerStream } from '../../api'
import * as googleTypes from '../../googleTypes'
import { Budget as arangodb_cloud_common_v1_Budget } from '../../common/v1/common'
import { Empty as arangodb_cloud_common_v1_Empty } from '../../common/v1/common'
import { IDOptions as arangodb_cloud_common_v1_IDOptions } from '../../common/v1/common'
import { ListOptions as arangodb_cloud_common_v1_ListOptions } from '../../common/v1/common'
import { Version as arangodb_cloud_common_v1_Version } from '../../common/v1/common'

// File: data/v1/data.proto
// Package: arangodb.cloud.data.v1

// CPUSize specifies the a specific level of CPU for a node.
export interface CPUSize {
  // System identifier of the size (e.g. standard)
  // string
  id?: string;
  
  // Human readable name of the size (e.g. Standard)
  // string
  name?: string;
}

// List of CPU sizes.
export interface CPUSizeList {
  // CPUSize
  items?: CPUSize[];
}

// Request arguments for CalculateDeploymentSize
export interface CalculateDeploymentSizeRequest {
  // Number of coordinators of the deployment
  // This field is ignored unless model is "flexible".
  // number
  coordinators?: number;
  
  // Amount of memory (in GB) to allocate for each coordinator.
  // This field is ignored unless model is "flexible".
  // number
  coordinator_memory_size?: number;
  
  // Number of dbservers of the deployment
  // This field is ignored unless model is "flexible".
  // number
  dbservers?: number;
  
  // Amount of memory (in GB) to allocate for each dbserver.
  // This field is ignored unless model is "flexible".
  // number
  dbserver_memory_size?: number;
  
  // Amount of disk space (in GB) to allocate for each dbserver.
  // This field is ignored unless model is "flexible".
  // number
  dbserver_disk_size?: number;
  
  // Type of model being used
  // string
  model?: string;
  
  // Size of nodes being used
  // string
  node_size_id?: string;
  
  // Number of nodes being used
  // number
  node_count?: number;
  
  // Amount of disk space per node (in GB)
  // number
  node_disk_size?: number;
  
  // ID of region in which the deployment will be created.
  // string
  region_id?: string;
}

// Instructions for connecting a driver to a deployment
export interface ConnectDriverInstructions {
  // Per driver instructions for connecting to a deployment
  // ConnectDriverInstructions_DriverInstructions
  drivers?: ConnectDriverInstructions_DriverInstructions[];
}

// Instructions for a specific driver
export interface ConnectDriverInstructions_DriverInstructions {
  // Human readable name of the driver.
  // E.g. "ArangoDB Go driver"
  // string
  name?: string;
  
  // Lines of code
  // string
  code?: string[];
  
  // Human readable remarks
  // string
  remarks?: string[];
  
  // URL for getting more information on the driver.
  // string
  driver_url?: string;
}

// Request arguments for CreateTestDatabase
export interface CreateTestDatabaseRequest {
  // The id of the deployment
  // string
  deployment_id?: string;
}

// CreateTestDatabaseResponse will contain the json formatted output of the create operation
export interface CreateTestDatabaseResponse {
  // Name of the created database
  // string
  db_name?: string;
  
  // Username of the created user
  // string
  username?: string;
  
  // Password of the created user
  // string
  password?: string;
  
  // Hostname of the database
  // string
  hostname?: string;
  
  // Port of the database
  // string
  port?: string;
}

// DataVolumeInfo provides information about a data volume
export interface DataVolumeInfo {
  // The total number of bytes of the data volume.
  // number
  total_bytes?: number;
  
  // The number of bytes used on the data volume.
  // number
  used_bytes?: number;
  
  // The number of bytes available on the data volume.
  // number
  available_bytes?: number;
  
  // The total number of inodes of the data volume.
  // number
  total_inodes?: number;
  
  // The number of inodes used on the data volume.
  // number
  used_inodes?: number;
  
  // The number of inodes available on the data volume.
  // number
  available_inodes?: number;
  
  // When this info has been measused
  // googleTypes.Timestamp
  measured_at?: googleTypes.Timestamp;
}

// A Deployment is represents one deployment of an ArangoDB cluster.
export interface Deployment {
  // System identifier of the deployment.
  // This is a read-only value.
  // string
  id?: string;
  
  // URL of this resource
  // This is a read-only value.
  // string
  url?: string;
  
  // Name of the deployment
  // string
  name?: string;
  
  // Description of the deployment
  // string
  description?: string;
  
  // Identifier of the project that owns this deployment.
  // After creation, this value cannot be changed.
  // string
  project_id?: string;
  
  // Identifier of the region in which the deployment is created.
  // After creation, this value cannot be changed.
  // string
  region_id?: string;
  
  // The creation timestamp of the deployment
  // This is a read-only value.
  // googleTypes.Timestamp
  created_at?: googleTypes.Timestamp;
  
  // The deletion timestamp of the deployment
  // This is a read-only value.
  // googleTypes.Timestamp
  deleted_at?: googleTypes.Timestamp;
  
  // Set when this deployment is deleted.
  // This is a read-only value.
  // boolean
  is_deleted?: boolean;
  
  // Optional identifier of the support plan selected for this deployment.
  // After creation, this value cannot be changed.
  // If no support plan identifier is set, the default support plan is used.
  // string
  support_plan_id?: string;
  
  // Identifier of the user who created this deployment.
  // This is a read-only value.
  // string
  created_by_id?: string;
  
  // This field must be set to the identifier of the current Terms&Conditions
  // when creating a deployment.
  // If the tier of the organization does not require a non-empty Terms&Condition
  // identifier, this field may be left empty.
  // This is a read-only value after creation.
  // string
  accepted_terms_and_conditions_id?: string;
  
  // If set, this deployment cannot be deleted.
  // To delete, first update the with locked set to false.
  // boolean
  locked?: boolean;
  
  // Indicates that this deployment is paused.
  // Use the data.ResumeDeployment method to resume (aka unpause) the deployment.
  // This is a read-only value.
  // boolean
  is_paused?: boolean;
  
  // The last paused timestamp of the deployment.
  // This is the timestamp that is_paused is transitioned from unset to set.
  // This is a read-only value.
  // googleTypes.Timestamp
  last_paused_at?: googleTypes.Timestamp;
  
  // The last resumed  timestamp of the deployment.
  // This is the timestamp that is_paused is transitioned from set to unset.
  // This is a read-only value.
  // googleTypes.Timestamp
  last_resumed_at?: googleTypes.Timestamp;
  
  // Identifier of the prepaid deployment that this deployment is attached to (if any).
  // This is a read-only value.
  // string
  prepaid_deployment_id?: string;
  
  // If set, authentication for Foxx requests is disabled.
  // Be default this field is not set for new deployments.
  // boolean
  disable_foxx_authentication?: boolean;
  
  // When the prepaid deployments starts (relevant when prepaid_deployment_id is set only)
  // This is a read-only value
  // googleTypes.Timestamp
  prepaid_deployment_starts_at?: googleTypes.Timestamp;
  
  // When the prepaid deployments ends (relevant when prepaid_deployment_id is set only)
  // This is a read-only value
  // googleTypes.Timestamp
  prepaid_deployment_ends_at?: googleTypes.Timestamp;
  
  // Whenever deployment can be updated to new values from prepaid deployment
  // This is a read-only value
  // boolean
  is_prepaid_deployment_update_available?: boolean;
  
  // Set if the deployment use a private endpoint.
  // This is a read-only value after creation.
  // If set during creation a private endpoint service will be created.
  // boolean
  private_endpoint?: boolean;
  
  // ArangoDB version to use for this deployment.
  // See Version.version.
  // If you change this value to a higher version,
  // the deployment will be upgraded.
  // If you change this value to a lower patch value,
  // the deployment will be downgraded.
  // Any attempt to change to a lower minor or major version
  // is considered an invalid request.
  // Any attempt to change to a version that is not in the
  // list of available versions is considered an invalid request.
  // string
  version?: string;
  
  // If set, this deployment will be upgraded to the ArangoDB release with the
  // version as listed in this field.
  // ReplaceVersionBy
  replace_version_by?: ReplaceVersionBy;
  
  // If set, it is recommended to upgrade to the ArangoDB release with the
  // version as listed in this field.
  // UpgradeVersionRecommendation
  upgrade_recommendation?: UpgradeVersionRecommendation;
  
  // If set, the version used by this deployment is no longer actively supported.
  // This is a readonly field.
  // boolean
  version_is_end_of_life?: boolean;
  
  // Deployment_CertificateSpec
  certificates?: Deployment_CertificateSpec;
  
  // Deployment_ServersSpec
  servers?: Deployment_ServersSpec;
  
  // Optional identifier of IP allowlist to use for this deployment.
  // string
  ipallowlist_id?: string;
  
  // Deployment_ModelSpec
  model?: Deployment_ModelSpec;
  
  // If provided, dataclusterd will use this custom image tag instead of the configured one for a given version.
  // Further, ImagePullPolicy will be set to Always.
  // This field can only be set by selected organizations.
  // string
  custom_image?: string;
  
  // Optional identifier of IAM provider to use for this deployment.
  // string
  iamprovider_id?: string;
  
  // Optional identifier of disk performance to use for this deployment.
  // string
  disk_performance_id?: string;
  
  // If set, the disk performance cannot be updated, due to other reasons than the disk rate limit.
  // This is a read-only value
  // boolean
  disk_performance_locked?: boolean;
  
  // Disk rate limit is the (fixed) period required to wait before a next disk action can be executed.
  // A value of 0 means that there is no rate limit.
  // If now - (last_disk_performance_updated_at or last_disk_size_updated_at) > disk_rate_limit_period it is not allowed to update
  // dbserver_disk_size or disk_performance_id.
  // googleTypes.Duration
  disk_rate_limit_period?: googleTypes.Duration;
  
  // Timestamp when the last time the disk performance has been updated.
  // This is a read-only value
  // googleTypes.Timestamp
  last_disk_performance_updated_at?: googleTypes.Timestamp;
  
  // Timestamp when the last time the disk size has been updated.
  // This is a read-only value
  // googleTypes.Timestamp
  last_disk_size_updated_at?: googleTypes.Timestamp;
  
  // Disk rate limit active is the active period required to wait before a next disk action can be executed.
  // The difference between disk_rate_limit_period and this field is that disk_rate_limit_period is a constant value
  // (independent) if the rate limit is active, this field will count down, if the field is 0 you can modify.
  // If this field is set it is not allowed to update dbserver_disk_size or disk_performance_id.
  // This is a read-only value
  // googleTypes.Duration
  disk_rate_limit_active?: googleTypes.Duration;
  
  // Deployment_Status
  status?: Deployment_Status;
  
  // Detailed size of the deployment
  // This is a read-only field.
  // DeploymentSize
  size?: DeploymentSize;
  
  // Deployment_Expiration
  expiration?: Deployment_Expiration;
  
  // Information about a backup restore.
  // If this field is set the deployment will be restored to that backup.
  // This is a read-only field. To set this field please use the backup service RestoreBackup method.
  // Deployment_BackupRestoreSpec
  backup_restore?: Deployment_BackupRestoreSpec;
  
  // Recommendations made for deployments using the "oneshard" or "sharded" model.
  // DeploymentSizeRecommendation
  deployment_recommendations?: DeploymentSizeRecommendation[];
  
  // Set if this deployment is a clone and should be handled differently. After bootstrapping a clone
  // needs a restore operation.
  // This is a read-only property
  // boolean
  is_clone?: boolean;
  
  // The ID of the backup to restore this clone deployment from.
  // This is a read-only property
  // string
  clone_backup_id?: string;
  
  // Deployment's notification settings
  // Deployment_NotificationSettings
  notification_settings?: Deployment_NotificationSettings;
  
  // Deployment's disk autoscaling settings
  // [Deprecated] This setting isn't supported anymore.
  // To increase disk space you need to switch to a larger node-size [e.g. A8 (with 80Gib)--> A16 (with 160 Gib)]
  // Deployment_DiskAutoSizeSettings
  disk_auto_size_settings?: Deployment_DiskAutoSizeSettings;
  
  // Determines if root's password scheduled rotation is enabled for the deployment.
  // This is a readonly field.
  // boolean
  is_scheduled_root_password_rotation_enabled?: boolean;
  
  // Time when the last time root password rotated for the deployment.
  // For the existing deployments, this field is not set until they enable scheduled root password rotation.
  // For the new deployments, this field will have the same value with deployment's `created_at`.
  // This is a readonly field.
  // googleTypes.Timestamp
  last_root_password_rotated_at?: googleTypes.Timestamp;
  
  // Optional identifier of the deployment profile for this deployment.
  // After creation, this value cannot be changed.
  // string
  deployment_profile_id?: string;
  
  // Determines if deployment is using platform authentication
  // boolean
  is_platform_authentication_enabled?: boolean;
  
  // The (optional) intended use-case for this deployment
  // string
  intended_use_case?: string;
  
  // If set to true, drop support for deprecated VST protocol and improve resilience.
  // Defaults to false.
  // boolean
  drop_vst_support?: boolean;
  
  // Defines notifications attached to the Deployment
  // Deployment_NotificationsEntry
  notifications?: { [key: string]: Notification };
}

// Information about a backup restore.
// All members of this message are read-only.
export interface Deployment_BackupRestoreSpec {
  // The revision of this BackupRestoreSpec
  // number
  revision?: number;
  
  // The timestamp of when the last revision has been updated.
  // googleTypes.Timestamp
  last_updated_at?: googleTypes.Timestamp;
  
  // Identifier of the user that restored this backup.
  // This is a read-only value.
  // string
  restored_by_id?: string;
  
  // Identifier of a backup to restore to.
  // string
  backup_id?: string;
}

// The status of backup restore
// All members of this message are read-only.
export interface Deployment_BackupRestoreStatus {
  // The revision of the used BackupRestoreSpec
  // number
  revision?: number;
  
  // The timestamp of when the backup restore status was updated.
  // googleTypes.Timestamp
  last_updated_at?: googleTypes.Timestamp;
  
  // Set if the deployment is preparing or restoring a backup
  // boolean
  restoring?: boolean;
  
  // Status of the restore backup operation.
  // Enum of the following values: "<empty>|Preparing|Restoring|Restored|Failed"
  // string
  status?: string;
  
  // Failure reason of the backup restore (if applicable)
  // string
  failure_reason?: string;
}
export interface Deployment_CertificateSpec {
  // Identifier of the CACertificate used to sign TLS certificates for the deployment.
  // If you change this value after the creation of the deployment a complete
  // rotation of the deployment is required, which will result in some downtime.
  // string
  ca_certificate_id?: string;
  
  // Zero or more DNS names to include in the TLS certificate of the deployment.
  // string
  alternate_dns_names?: string[];
}

// Deployment's disk autoscaling settings
export interface Deployment_DiskAutoSizeSettings {
  // Maximum allowed disk size that a node can reach (in GB).
  // [Deprecated] This setting isn't supported anymore.
  // number
  maximum_node_disk_size?: number;
}

// Expiration of the deployment.
// All members of this message are read-only.
export interface Deployment_Expiration {
  // The expiration timestamp of the deployment
  // If not set, the deployment will not expire.
  // googleTypes.Timestamp
  expires_at?: googleTypes.Timestamp;
  
  // Human readable reason for why the deployment expires (or does not expire).
  // string
  reason?: string;
  
  // The timestamp of when the last "this deployment will expire at" email was
  // send.
  // If not set, no such email has been send.
  // googleTypes.Timestamp
  last_warning_email_send_at?: googleTypes.Timestamp;
  
  // List of email addresses to which the last warning email has been send.
  // Not set when no such email has been send.
  // string
  last_warning_email_send_to?: string[];
}
export interface Deployment_ModelSpec {
  // Type of model being used
  // string
  model?: string;
  
  // Size of nodes being used
  // This field is ignored set in case the flexible model is used.
  // string
  node_size_id?: string;
  
  // Number of nodes being used
  // This field is ignored set in case the flexible model is used.
  // number
  node_count?: number;
  
  // Amount of disk space per node (in GB)
  // This field is ignored set in case the flexible model is used.
  // number
  node_disk_size?: number;
}

// Deployment's notification settings
export interface Deployment_NotificationSettings {
  // Email addresses that notifications related to this deployment should be sent to.
  // string
  email_addresses?: string[];
}
export interface Deployment_NotificationsEntry {
  // string
  key?: string;
  
  // Notification
  value?: Notification;
}

// Status of a single server (of the ArangoDB cluster)
export interface Deployment_ServerStatus {
  // ID of the server
  // string
  id?: string;
  
  // Type of server (agent|coordinator|dbserver)
  // string
  type?: string;
  
  // Human readable description of the status of the deployment.
  // string
  description?: string;
  
  // The creation timestamp of the server
  // googleTypes.Timestamp
  created_at?: googleTypes.Timestamp;
  
  // Set once the server is ready
  // boolean
  ready?: boolean;
  
  // Set once the server has been known to be a member of the cluster
  // boolean
  member_of_cluster?: boolean;
  
  // Set if the server is in a failed state
  // Every server is always in 1 (and only 1) of these states: failed/creating/ok/bad/upgrading.
  // boolean
  failed?: boolean;
  
  // Set if the server is still being created
  // Every server is always in 1 (and only 1) of these states: failed/creating/ok/bad/upgrading.
  // boolean
  creating?: boolean;
  
  // Set if the server is in the ok state.
  // Every server is always in 1 (and only 1) of these states: failed/creating/ok/bad/upgrading.
  // boolean
  ok?: boolean;
  
  // Set if the server is in the bad state.
  // Every server is always in 1 (and only 1) of these states: failed/creating/ok/bad/upgrading.
  // boolean
  bad?: boolean;
  
  // Set if the server is still being upgraded
  // Every server is always in 1 (and only 1) of these states: failed/creating/ok/bad/upgrading.
  // boolean
  upgrading?: boolean;
  
  // Latest known ArangoDB version used by this server.
  // Initially this field is empty.
  // string
  version?: string;
  
  // The last started timestamp of the server
  // googleTypes.Timestamp
  last_started_at?: googleTypes.Timestamp;
  
  // If set, a rotation of this server has been requested.
  // boolean
  rotation_pending?: boolean;
  
  // If set, this server reports that it can be deleted.
  // boolean
  can_be_deleted?: boolean;
  
  // If set, this server is leader in its type (currently applies only to agents).
  // boolean
  is_leader?: boolean;
  
  // Information about the data volume used to store the data
  // DataVolumeInfo
  data_volume_info?: DataVolumeInfo;
  
  // Recent number of restarts
  // number
  recent_restarts?: number;
  
  // Last known memory usage in bytes
  // number
  last_memory_usage?: number;
  
  // Last known CPU usage in vCPU units
  // number
  last_cpu_usage?: number;
  
  // Last known memory limit in bytes
  // number
  last_memory_limit?: number;
  
  // Last known CPU limit in vCPU units
  // number
  last_cpu_limit?: number;
}
export interface Deployment_ServersSpec {
  // Number of coordinators of the deployment
  // This field is automatically set unless the flexible model is used.
  // number
  coordinators?: number;
  
  // Amount of memory (in GB) to allocate for coordinators.
  // This field is automatically set unless the flexible model is used.
  // number
  coordinator_memory_size?: number;
  
  // Custom command line arguments passed to all coordinators.
  // This field is ignored set unless the flexible model is used.
  // string
  coordinator_args?: string[];
  
  // Number of dbservers of the deployment
  // This field is automatically set unless the flexible model is used.
  // number
  dbservers?: number;
  
  // Amount of memory (in GB) to allocate for dbservers.
  // This field is automatically set unless the flexible model is used.
  // number
  dbserver_memory_size?: number;
  
  // Amount of disk space (in GB) to allocate for dbservers.
  // This field is automatically set unless the flexible model is used.
  // number
  dbserver_disk_size?: number;
  
  // Custom command line arguments passed to all dbservers.
  // This field is ignored set unless the flexible model is used.
  // string
  dbserver_args?: string[];
  
  // The minimum number of dbservers based on the highest replication factor
  // defined by all databases and all collections.
  // number
  minimum_dbservers_count?: number;
  
  // The minimum amount of disk space (in GB) to allocate for dbservers based on the highest usage of all running DB servers.
  // The nearest size (larger then indicated here) need to be depected if the provider doesn't support all values (See GetServersSpecLimits)
  // number
  minimum_dbserver_disk_size?: number;
}

// Status of the deployment
// All members of this field are read-only.
export interface Deployment_Status {
  // Endpoint URL used to reach the deployment
  // This value will be empty during the creation of the deployment.
  // This field always contains the URL of the low port (8529) of the
  // deployment.
  // If a certificate with well known certificate is used, this
  // port is using the well known certificate.
  // Otherwise this port is using the self-signed certificate.
  // string
  endpoint?: string;
  
  // Human readable description of the status of the deployment.
  // string
  description?: string;
  
  // Set once the deployment has been created.
  // boolean
  created?: boolean;
  
  // Set if the deployment is ready to be used.
  // If the deployment has downtime (e.g. because of changing a CA certificate)
  // this will go to false until the downtime is over.
  // boolean
  ready?: boolean;
  
  // Set if the deployment is being upgraded.
  // boolean
  upgrading?: boolean;
  
  // Versions of running servers
  // string
  server_versions?: string[];
  
  // Status of individual servers of the deployment
  // Deployment_ServerStatus
  servers?: Deployment_ServerStatus[];
  
  // Set if the ready boolean is transitioned to true for the very first time.
  // googleTypes.Timestamp
  bootstrapped_at?: googleTypes.Timestamp;
  
  // Set if bootstrapped_at has a value, otherwise false.
  // boolean
  bootstrapped?: boolean;
  
  // Endpoint URL used to reach the deployment on the port that uses
  // the self-signed certificate.
  // This endpoint is recommended for machine-to-database connections.
  // string
  endpoint_self_signed?: string;
  
  // Endpoint URL used to reach the deployment on default port (443)
  // This value will be empty during the creation of the deployment.
  // If a certificate with well known certificate is used, this
  // port is using the well known certificate.
  // Otherwise this port is using the self-signed certificate.
  // This endpoint is recommended for human-to-database connections.
  // string
  endpoint_default?: string;
  
  // If set, this deployment has a private endpoint, however can contain the public endpoint as well.
  // When switching from a public endpoint to a private endpoint the public endpoint will
  // be available for an hour to support seemlessly migration to the private endpoint.
  // boolean
  private_endpoint?: boolean;
  
  // If set, this deployment has a private endpoint only.
  // When switching from a public endpoint to a private endpoint the public endpoint will
  // be available for an hour to support seemlessly migration to the private endpoint.
  // boolean
  private_endpoint_only?: boolean;
  
  // Endpoint URL used to reach the deployment which is configured as a private endpoint.
  // This field always contains the URL of the low port (8529) of the
  // deployment.
  // If a certificate with well known certificate is used, this
  // port is using the well known certificate, note that the alternate DNS names are not part of this certificate.
  // Otherwise this port is using the self-signed certificate.
  // This endpoint is recommended for human-to-database connections.
  // If no private endpoint is configured this field will be empty.
  // string
  endpoint_private_endpoint?: string;
  
  // Endpoint URL used to reach the deployment on the port that uses
  // the self-signed certificate.
  // This certificate will contain the specified alternate DNS names as well,
  // so a secure TLS connection can be establised.
  // This endpoint is recommended for machine-to-database connections.
  // If no private endpoint is configured this field will be empty.
  // string
  endpoint_private_endpoint_self_signed?: string;
  
  // Private Endpoint URL used to reach the deployment on default port (443)
  // This value will be empty during the creation of the deployment & private endpoint.
  // this port is using the well known certificate.
  // string
  endpoint_private_endpoint_default?: string;
  
  // The status of backup restore (if applicable).
  // This field will be set to empty if a new revision of the spec is available
  // Deployment_BackupRestoreStatus
  backup_restore_status?: Deployment_BackupRestoreStatus;
  
  // The total size of all backups in the external source (in bytes)
  // number
  total_backup_size_bytes?: number;
  
  // Set if there is any backup currently uploading data to the external source
  // boolean
  backup_upload_in_progress?: boolean;
  
  // Set if the deployment is up-to-date and has no pending updates.
  // boolean
  is_up_to_date?: boolean;
  
  // Set if the deployment is in read-only mode.
  // boolean
  read_only?: boolean;
}

// Result for GetDeploymentCredentials
export interface DeploymentCredentials {
  // Name of the user for which credentials were asked.
  // Default to username of root user.
  // string
  username?: string;
  
  // Password of the user for which credentials were asked.
  // string
  password?: string;
}

// Request arguments for GetDeploymentCredentials
export interface DeploymentCredentialsRequest {
  // Identifier of deployment to request credentials for.
  // string
  deployment_id?: string;
  
  // Reason for requesting the credentials
  // string
  reason?: string;
}

// Features that are available to new deployments in a given context.
export interface DeploymentFeatures {
  // Is the use of an IAM provider available?
  // boolean
  iamprovider?: boolean;
  
  // Is the use of the pause feature available?
  // boolean
  pause?: boolean;
  
  // Is the use of the ML features available?
  // boolean
  ml?: boolean;
  
  // Is the use of monitoring feature available?
  // boolean
  monitoring?: boolean;
}

// Request arguments for GetDeploymentFeatures
export interface DeploymentFeaturesRequest {
  // Identifier of project that will own a deployment.
  // string
  project_id?: string;
  
  // Identifier of a region in which a deployment will be created.
  // string
  region_id?: string;
  
  // Model of the intended deployment.
  // string
  model?: string;
  
  // Node size use for the intended deployments
  // string
  node_size_id?: string;
}

// List of Deployments.
export interface DeploymentList {
  // Actual deployments
  // Deployment
  items?: Deployment[];
  
  // Budget for deployments
  // arangodb.cloud.common.v1.Budget
  budget?: arangodb_cloud_common_v1_Budget;
}

// DeploymentModel specifies the a specific model of deploying
// arangodb clusters.
export interface DeploymentModel {
  // System identifier of the model (e.g. oneshard)
  // string
  id?: string;
  
  // Human readable name of the model (e.g. One shard)
  // string
  name?: string;
  
  // DeploymentModel_Features
  features?: DeploymentModel_Features;
  
  // Limit the time-to-live of deployments created with this model.
  // Time-to-live (till expiration) is expressed in seconds.
  // A value of '0' means no expiration.
  // number
  deployment_ttl?: number;
}

// Features that are available to deployments of this model type.
export interface DeploymentModel_Features {
  // If set, ML is available as a trial only.
  // boolean
  ml_free_trial?: boolean;
  
  // If set, private endpoints are allowed for this deployment model.
  // boolean
  private_endpoints?: boolean;
  
  // If set, metrics endpoint integration is allowed for this deployment model.
  // boolean
  metrics_endpoint?: boolean;
  
  // If set, backups for this deployment model are allowed to be uploaded to the cloud.
  // boolean
  backup_uploads?: boolean;
}

// List of deployment models.
export interface DeploymentModelList {
  // DeploymentModel
  items?: DeploymentModel[];
}
export interface DeploymentPrice {
  // Price per hour in given currency for the deployment.
  // number
  price_per_hour?: number;
  
  // Network transfer prices (variable depending on usage)
  // DeploymentPrice_NetworkTransferPrice
  network_transfer_prices?: DeploymentPrice_NetworkTransferPrice[];
  
  // Backup prices (variable depending on usage)
  // DeploymentPrice_BackupPrice
  backup_price?: DeploymentPrice_BackupPrice;
  
  // Identifier of the currency in which the price is specified.
  // string
  currency_id?: string;
  
  // AuditLog prices (variable depending on usage)
  // DeploymentPrice_AuditLogPrice
  auditlog_price?: DeploymentPrice_AuditLogPrice;
}
export interface DeploymentPrice_AuditLogPrice {
  // Price per GB/hour of audit logs in cloud storage (destination=cloud)
  // number
  price_per_gb_per_hour?: number;
  
  // Price per 1.000 HTTPS Post invocations (destination=https-post)
  // number
  https_post_invocation_price_per_1000?: number;
  
  // Price per GB HTTPS Post body size (destination=https-post)
  // number
  https_post_body_size_price_per_gb?: number;
}
export interface DeploymentPrice_BackupPrice {
  // Price per GB/hour of uploaded backup storage
  // number
  price_per_gb_per_hour?: number;
}
export interface DeploymentPrice_NetworkTransferPrice {
  // Price per GB of network transfer into the database
  // number
  ingress_price_per_gb?: number;
  
  // Price per GB of network transfer out of the database
  // number
  egress_price_per_gb?: number;
  
  // Description of this price
  // string
  description?: string;
}

// Arguments for requesting a price a deployment of given properties.
export interface DeploymentPriceRequest {
  // Identifier of organization containing the deployment.
  // string
  organization_id?: string;
  
  // Identifier of project containing the deployment.
  // string
  project_id?: string;
  
  // Identifier of the support plan of the deployment.
  // string
  support_plan_id?: string;
  
  // Identifier of the cloud provider of the deployment.
  // string
  cloud_provider_id?: string;
  
  // Identifier of the cloud region of the deployment.
  // string
  cloud_region_id?: string;
  
  // Model of the deployment.
  // string
  model?: string;
  
  // Node size use for deployments
  // string
  node_size_id?: string;
  
  // Number of nodes being used
  // This field is ignored if model is "flexible".
  // number
  node_count?: number;
  
  // Amount of disk space per node (in GB)
  // This field is ignored if model is "flexible".
  // number
  node_disk_size?: number;
  
  // Number of coordinators of the deployment
  // This field is ignored unless model is "flexible".
  // number
  coordinators?: number;
  
  // Amount of memory (in GB) to allocate for each coordinator.
  // This field is ignored unless model is "flexible".
  // number
  coordinator_memory_size?: number;
  
  // Number of dbservers of the deployment
  // This field is ignored unless model is "flexible".
  // number
  dbservers?: number;
  
  // Amount of memory (in GB) to allocate for each dbserver.
  // This field is ignored unless model is "flexible".
  // number
  dbserver_memory_size?: number;
  
  // Amount of disk space (in GB) to allocate for each dbserver.
  // This field is ignored unless model is "flexible".
  // number
  dbserver_disk_size?: number;
  
  // Identifier of disk performance used for this deployment (if any).
  // string
  disk_performance_id?: string;
  
  // If set, request for prices based on ArangoGraph credits.
  // boolean
  use_credit_pricing?: boolean;
}

// Result of CalculateDeploymentSize
export interface DeploymentSize {
  // Number of agents
  // number
  agents?: number;
  
  // Amount of memory (in GB) to allocate for each agent.
  // number
  agent_memory_size?: number;
  
  // Amount of disk space (in GB) to allocate for each agent.
  // number
  agent_disk_size?: number;
  
  // Total (combined) amount of memory (in GB) used by all servers (agents, coordinators & dbservers)
  // number
  total_memory_size?: number;
  
  // Total (combined) amount of disk space (in GB) used by all servers (agents & dbservers)
  // number
  total_disk_size?: number;
  
  // Number of coordinators
  // number
  coordinators?: number;
  
  // Amount of memory (in GB) to allocate for each coordinator.
  // number
  coordinator_memory_size?: number;
  
  // Number of dbservers
  // number
  dbservers?: number;
  
  // Amount of memory (in GB) to allocate for each dbserver.
  // number
  dbserver_memory_size?: number;
  
  // Amount of disk space (in GB) to allocate for each dbserver.
  // number
  dbserver_disk_size?: number;
}

// Response of RecommendDeploymentSize.
export interface DeploymentSizeRecommendation {
  // Request that resulted in this recommendation.
  // DeploymentSizeRequest
  request?: DeploymentSizeRequest;
  
  // Time when the recommendation was made.
  // googleTypes.Timestamp
  created_at?: googleTypes.Timestamp;
  
  // Amount of memory space per node (in GB) being recommended
  // number
  node_memory_size?: number;
  
  // Amount of disk space per node (in GB) being recommended
  // number
  node_disk_size?: number;
  
  // Number of nodes being recommended
  // number
  node_count?: number;
  
  // If set, this recommendation does not fit in
  // the callers quota.
  // boolean
  exceeds_quota?: boolean;
  
  // If set, this recommendation does not fit in
  // the ArangoGraph Insights Platform.
  // boolean
  exceeds_platform?: boolean;
}

// Request arguments for RecommendDeploymentSize.
export interface DeploymentSizeRequest {
  // Size of entire dataset (on disk) in GB.
  // Required field.
  // Must be >= 1.
  // number
  dataset_size?: number;
  
  // Primary use case for the database
  // Possible values:
  // - GRAPH
  // - DOCUMENT
  // - MULTIMODEL
  // - KEYVALUE
  // string
  usecase?: string;
  
  // Customer preferred model
  // string
  model?: string;
  
  // File format on dataset
  // Possible values:
  // - JSON
  // - CSV
  // string
  file_format?: string;
  
  // Number of documents in the entire dataset (in case of JSON).
  // Number of rows in the entire dataset (in case of CSV).
  // number
  number_of_documents?: number;
  
  // Largest number of columns of the dataset (in case of CSV).
  // number
  number_of_columns?: number;
  
  // Percentage of dataset_size that is considered "hot"
  // Must be >= 0.0 and <= 1.0
  // number
  working_set_percentage?: number;
  
  // Percentage of operations that are READ
  // Must be >= 0.0 and <= 1.0
  // number
  access_read_percentage?: number;
  
  // Percentage of operations that are CREATE
  // Must be >= 0.0 and <= 1.0
  // number
  access_create_percentage?: number;
  
  // Percentage of operations that are UPDATE
  // Must be >= 0.0 and <= 1.0
  // number
  access_update_percentage?: number;
  
  // Increase factor of the dataset_size in 1 year.
  // number
  growth_rate?: number;
  
  // Desired number of replicas.
  // Must be >= 3 and <= 5
  // number
  replication_factor?: number;
  
  // Identifier of project to request a recommendation in
  // string
  project_id?: string;
  
  // Identifier of region to request a recommendation in
  // string
  region_id?: string;
}

// DiskPerformance provides information on a specific disk performance option.
// All fields in this message are read-only values.
export interface DiskPerformance {
  // System identifier of the disk-performance.
  // string
  id?: string;
  
  // Name of the disk-performance.
  // string
  name?: string;
  
  // Description of the disk-performance.
  // string
  description?: string;
  
  // If set, this is the default disk performance (inside the requested region).
  // boolean
  is_default?: boolean;
}

// List of DiskPerformances.
export interface DiskPerformanceList {
  // DiskPerformance
  items?: DiskPerformance[];
}

// GetDiskPerformanceRequest is used as request in GetDiskPerformance
export interface GetDiskPerformanceRequest {
  // disk performance identifier (e.g. 'DP30')
  // string
  disk_performance_id?: string;
  
  // Identifier of the region (e.g. 'aks-westeurope').
  // string
  region_id?: string;
}

// Instructions for importing data into a deployment
export interface ImportDataInstructions {
  // Lines of code to run arangorestore
  // string
  import_dump?: string[];
  
  // Lines of code to run arangoimport with json file format
  // string
  arango_import_json?: string[];
  
  // Lines of code to run arangoimport with csv file format
  // string
  arango_import_csv?: string[];
  
  // Lines of code to run arangoimport with tsv file format
  // string
  arango_import_tsv?: string[];
}

// Request arguments for ListCPUSizes
export interface ListCPUSizesRequest {
  // Identifier of project that will own a deployment.
  // string
  project_id?: string;
  
  // Optional identifier of a deployment for which CPU sizes are requested.
  // If specified, only those CPU sizes are listed, that an existing deployment is allowed to have.
  // If specified, project_id field is ignored, and instead read from the deployment.
  // string
  deployment_id?: string;
}

// Request arguments for ListDeploymentModels
export interface ListDeploymentModelsRequest {
  // Identifier of project that will own a deployment.
  // string
  project_id?: string;
  
  // Optional identifier of a deployment, so the current model can be added to the list if needed
  // This deployment should be inside the provided project
  // string
  deployment_id?: string;
}

// Request arguments for ListDeploymentsByFilter.
export interface ListDeploymentsByFilterRequest {
  // Identifier of the organization to request the deployments for.
  // string
  organization_id?: string;
  
  // Return only deployments created in this project.
  // This is an optional field.
  // string
  project_id?: string;
  
  // Return only deployments created in this region.
  // This is an optional field.
  // string
  region_id?: string;
  
  // Return only deployments with an expiration date after this timestamp.
  // This will exclude deployments that have no expiration date.
  // This is an optional field.
  // googleTypes.Timestamp
  expires_after?: googleTypes.Timestamp;
  
  // Return only deployments with an expiration date before this timestamp.
  // This will exclude deployments that have no expiration date.
  // This is an optional field.
  // googleTypes.Timestamp
  expires_before?: googleTypes.Timestamp;
  
  // Return only deployments that do not expire.
  // boolean
  does_not_expire?: boolean;
  
  // Optional common list options, the context_id is ignored
  // arangodb.cloud.common.v1.ListOptions
  options?: arangodb_cloud_common_v1_ListOptions;
}

// ListDiskPerformancesRequest is used as request in ListAllDiskPerformances
export interface ListDiskPerformancesRequest {
  // Identifier of the region (e.g. 'aks-westeurope').
  // This field is ignored when a deployment_id is provided, otherwise required.
  // string
  region_id?: string;
  
  // Identifier of the node size (e.g. 'A16').
  // This field is ignored when a deployment_id is provided, otherwise required.
  // string
  node_size_id?: string;
  
  // Amount of disk space (in GB) to allocate for each dbserver.
  // This field is ignored when a deployment_id is provided, otherwise required.
  // number
  dbserver_disk_size?: number;
  
  // Optional identifier of the organization for which the disk performances need to be listed.
  // If specified, only those disk performances are listed that the organization is allowed to use
  // for a deployment of a given node_size_id.
  // This field is ignored when a deployment_id is provided.
  // string
  organization_id?: string;
  
  // Identifier of the deloyment, used to fill-out the region, node-size and disk-size.
  // string
  deployment_id?: string;
}

// Request arguments for ListVersions.
export interface ListVersionsRequest {
  // Common list options
  // arangodb.cloud.common.v1.ListOptions
  options?: arangodb_cloud_common_v1_ListOptions;
  
  // If set, the result includes all versions for that are available for the
  // organization identified by this ID.
  // If not set, only versions are returned that are available to all organizations.
  // string
  organization_id?: string;
  
  // If set, only versions will be returned that are safe to upgrade to from this version.
  // string
  current_version?: string;
}

// NodeSize specifies the size constraints of different data nodes.
export interface NodeSize {
  // System identifier of the node size
  // string
  id?: string;
  
  // Human readable name of the node size
  // string
  name?: string;
  
  // Amount of memory (in GB) that is available on this size of node.
  // number
  memory_size?: number;
  
  // Minimum amount of disk (in GB) that is available on this size of node.
  // number
  min_disk_size?: number;
  
  // Maximum amount of disk (in GB) that is available on this size of node.
  // number
  max_disk_size?: number;
  
  // CPU size that is available on this size of node (e.g. standard or high).
  // string
  cpu_size?: string;
  
  // If set, contains list of possible disk sizes (in GB) for the node.
  // In that case, this field overrides the min_disk_size & max_disk_size fields.
  // number
  disk_sizes?: number[];
}

// List of node sizes.
export interface NodeSizeList {
  // NodeSize
  items?: NodeSize[];
}

// Request arguments for ListNodeSizes
export interface NodeSizesRequest {
  // Identifier of project that will own a deployment.
  // string
  project_id?: string;
  
  // Identifier of a region in which a deployment will be created.
  // string
  region_id?: string;
  
  // If set, project_id & region_id will be taken from this deployment.
  // This also causes the node_size used by this deployment to be included
  // in the result, if it it would not match for new deployments.
  // string
  deployment_id?: string;
  
  // Identifier of a model of a new deployment will be created.
  // If set, all node sizes available for this model will be returned,
  // otherwise only node sizes that have no restrictions on model will be returned.
  // string
  model?: string;
  
  // Optional identifier of organization that will own a deployment.
  // Ignored if project_id or deployment_id is set.
  // string
  organization_id?: string;
  
  // Optional field to include node sizes that are otherwise restricted
  // for the specified projectID / organizationID.
  // By default, the result is restricted based on the organization and project tiers/quotas.
  // Ignored when project_id is "all" and organization_id is not provided.
  // boolean
  include_restricted?: boolean;
}

// Define the Notification details
export interface Notification {
  // String representation of the Notification
  // string
  notification?: string;
  
  // Notification Severity
  // NotificationSeverity
  severity?: NotificationSeverity;
  
  // The timestamp of when the notification has been created.
  // googleTypes.Timestamp
  created_at?: googleTypes.Timestamp;
  
  // The timestamp of when the notification has been updated.
  // googleTypes.Timestamp
  updated_at?: googleTypes.Timestamp;
  
  // The timestamp of when the notification expires.
  // googleTypes.Timestamp
  expires_at?: googleTypes.Timestamp;
}

// RebalanceDeploymentShardsRequest request for rebalancing shards for a deployment
export interface RebalanceDeploymentShardsRequest {
  // The id of the deployment
  // string
  deployment_id?: string;
}

// ReplaceVersionBy holds replacement instructions.
export interface ReplaceVersionBy {
  // The version of the ArangoDB release that it will be upgraded to.
  // Version in the format of major.minor.patch
  // Format details can be found at: https://semver.org/
  // string
  version?: string;
  
  // Human readable reason why this version will be replaced.
  // string
  reason?: string;
  
  // Date when the current version will be replaced automatically with the new version.
  // If this field isn’t set (or present) it means no automatic update will happen.
  // googleTypes.Timestamp
  auto_update_date?: googleTypes.Timestamp;
}

// RotateDeploymentServerRequest request for rotating out servers for a deployment
export interface RotateDeploymentServerRequest {
  // The id of the deployment this server belongs to.
  // string
  deployment_id?: string;
  
  // The id of the server to rotate out.
  // string
  server_id?: string;
}

// Limits of allowed values for fields of Deployment.ServersSpec.
export interface ServersSpecLimits {
  // Limits for the number of coordinators of the deployment
  // ServersSpecLimits_Limits
  coordinators?: ServersSpecLimits_Limits;
  
  // Possible values for the amount of memory (in GB) to allocate
  // for coordinators.
  // ServersSpecLimits_Limits
  coordinator_memory_size?: ServersSpecLimits_Limits;
  
  // Limits for the number of dbservers of the deployment
  // ServersSpecLimits_Limits
  dbservers?: ServersSpecLimits_Limits;
  
  // Possible values for the amount of memory (in GB) to allocate
  // for dbservers.
  // ServersSpecLimits_Limits
  dbserver_memory_size?: ServersSpecLimits_Limits;
  
  // Amount of disk space (in GB) to allocate for dbservers.
  // ServersSpecLimits_Limits
  dbserver_disk_size?: ServersSpecLimits_Limits;
  
  // Possible values for the amount of memory (in GB) to allocate
  // for pairs of coordinator, dbserver.
  // ServersSpecLimits_Limits
  node_memory_size?: ServersSpecLimits_Limits;
  
  // Possible values for the number of nodes.
  // Value must be min/max (not using allowed_values)
  // ServersSpecLimits_Limits
  node_count?: ServersSpecLimits_Limits;
}
export interface ServersSpecLimits_Limits {
  // Minimum value
  // number
  min?: number;
  
  // Maximum value
  // number
  max?: number;
  
  // Set of allowed values.
  // If this field is non-empty, only one of these values
  // is allowed.
  // number
  allowed_values?: number[];
}

// Request arguments for ListServersSpecLimits
export interface ServersSpecLimitsRequest {
  // Identifier of project that will own a deployment.
  // string
  project_id?: string;
  
  // Identifier of a region in which a deployment will be created.
  // string
  region_id?: string;
  
  // Optional identifier of a deployment for which compatible server specifications are request.
  // string
  deployment_id?: string;
}

// Request arguments for UpdateDeploymentScheduledRootPasswordRotation
export interface UpdateDeploymentScheduledRootPasswordRotationRequest {
  // Identifier of the deloyment.
  // string
  deployment_id?: string;
  
  // Whether scheduled root password rotation should be enabled or not.
  // boolean
  enabled?: boolean;
}

// UpgradeVersionRecommendation holds a recommendation for updating this version.
export interface UpgradeVersionRecommendation {
  // The version of the ArangoDB release that it it is recommend to upgraded to.
  // Version in the format of major.minor.patch
  // Format details can be found at: https://semver.org/
  // string
  version?: string;
  
  // Human readable reason why this version should be upgraded.
  // string
  reason?: string;
}

// Version of an ArangoDB release
export interface Version {
  // Version in the format of major.minor.patch
  // Format details can be found at: https://semver.org/
  // string
  version?: string;
  
  // If set, deployments using this version will be upgraded to the ArangoDB release with the
  // version as listed in this message.
  // ReplaceVersionBy
  replace_by?: ReplaceVersionBy;
  
  // If set, deployments using this version are recommend to be upgraded to the ArangoDB release with the
  // version as listed in this message.
  // UpgradeVersionRecommendation
  upgrade_recommendation?: UpgradeVersionRecommendation;
  
  // If set, this version is not longer actively supported.
  // boolean
  is_end_of_life?: boolean;
  
  // ArangoDB version release notes
  // string
  release_notes_url?: string;
}

// List of Versions.
export interface VersionList {
  // Version
  items?: Version[];
}

// NotificationSeverity keeps possible severities for notifications
export enum NotificationSeverity {
  // Defines Info level Notification Severity
  NOTIFICATION_SEVERITY_INFO = 0,
  
  // Defines Warning level Notification Severity
  NOTIFICATION_SEVERITY_WARNING = 1,
  
  // Defines Critical level Notification Severity
  NOTIFICATION_SEVERITY_CRITICAL = 2,
}

// DataService is the API used to configure data objects.
export interface IDataService {
  // Get the current API version of this service.
  // Required permissions:
  // - None
  GetAPIVersion: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_common_v1_Version>;
  
  // Fetch all deployments in the project identified by the given context ID.
  // Required permissions:
  // - data.deployment.list on the project identified by the given context ID
  ListDeployments: (req: arangodb_cloud_common_v1_ListOptions) => Promise<DeploymentList>;
  
  // Fetch all deployments in the organization identified by the given filter.
  // Only the deployments with sufficient permissions are returned.
  // If the caller has no such permission on any of the deployments (in the organization) an empty list is returned, not an error.
  // Required permissions:
  // - None (authenticated only)
  // - data.deployment.list on the filtered deployment(s)
  ListDeploymentsByFilter: (req: ListDeploymentsByFilterRequest) => Promise<DeploymentList>;
  
  // Fetch a deployment by its id.
  // Required permissions:
  // - data.deployment.get on the deployment identified by the given ID
  GetDeployment: (req: arangodb_cloud_common_v1_IDOptions) => Promise<Deployment>;
  
  // Fetch all updates (spec and/or status) of the deployment that matches the given request.
  // Required permissions:
  // - data.deployment.get on the deployment identified by the given ID
  GetDeploymentUpdates: (req: arangodb_cloud_common_v1_IDOptions, cb: (obj: IStreamMessage<Deployment>) => void) => Promise<IServerStream>;
  
  // Create a new deployment
  // Required permissions:
  // - data.deployment.create on the project that owns the deployment
  // - network.privateendpointservice.create on the project that owns the deployment (if private_endpoint field is set)
  // Note that deployment.status & deployment.expiration are ignored
  // in this request.
  CreateDeployment: (req: Deployment) => Promise<Deployment>;
  
  // Update a deployment
  // Required permissions:
  // - data.deployment.update on the deployment
  // Note that deployment.status & deployment.expiration are ignored
  // in this request.
  UpdateDeployment: (req: Deployment) => Promise<Deployment>;
  
  // Update the setting for scheduled root password rotation
  // Required permissions:
  // - data.deployment.update-scheduled-root-password-rotation
  UpdateDeploymentScheduledRootPasswordRotation: (req: UpdateDeploymentScheduledRootPasswordRotationRequest) => Promise<void>;
  
  // Delete a deployment
  // Note that deployments are initially only marked for deletion.
  // Once all their resources are removed the deployment itself is removed.
  // Required permissions:
  // - data.deployment.delete on the deployment
  DeleteDeployment: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Fetch credentials for accessing deployment by its id.
  // Required permissions:
  // - data.deployment.get on the deployment identified by the given ID
  // - data.deploymentcredentials.get on the deployment identified by the given ID
  GetDeploymentCredentials: (req: DeploymentCredentialsRequest) => Promise<DeploymentCredentials>;
  
  // Fetch all ArangoDB versions that are available for deployments.
  // Required permissions:
  // - None
  ListVersions: (req: ListVersionsRequest) => Promise<VersionList>;
  
  // Fetch the default ArangoDB version for new deployment.
  // Required permissions:
  // - None
  GetDefaultVersion: (req?: arangodb_cloud_common_v1_Empty) => Promise<Version>;
  
  // Fetch the ArangoDB version by its id (semver).
  // Required permissions:
  // - None
  GetVersion: (req: arangodb_cloud_common_v1_IDOptions) => Promise<Version>;
  
  // Fetch the limits for server specifications for deployments
  // owned by the given projected, created in the given region.
  // Required permissions:
  // - data.limits.get on the requested project
  // - data.deployment.get on the specified deployment (if deployment_id is set)
  // This method is deprecated and will be remove in the very near future, do not use this method anymore
  GetServersSpecLimits: (req: ServersSpecLimitsRequest) => Promise<ServersSpecLimits>;
  
  // Fetch the node sizes available for deployments
  // owned by the project with given ID, created in the given region with given ID.
  // If project ID "all" is used, then all node sizes for the region with given
  // ID are returned.
  // Required permissions:
  // - data.nodesize.list on the requested deployment (if deployment ID is set)
  // - data.nodesize.list on the requested project (if project ID does not equal "all")
  // - data.nodesize.list on the requested organization (if organization ID is set)
  // - None if project ID does equals "all"
  ListNodeSizes: (req: NodeSizesRequest) => Promise<NodeSizeList>;
  
  // Fetch the models available for deployments owned by the project with given ID.
  // Required permissions:
  // - data.deploymentmodel.list on the requested project
  ListDeploymentModels: (req: ListDeploymentModelsRequest) => Promise<DeploymentModelList>;
  
  // Fetch the CPU sizes available for deployments owned by the project with given ID.
  // Required permissions:
  // - data.cpusize.list on the requested project
  ListCPUSizes: (req: ListCPUSizesRequest) => Promise<CPUSizeList>;
  
  // Get the deployment model identified by the provided ID.
  // Required permissions:
  // - None (authenticated only)
  GetDeploymentModel: (req: arangodb_cloud_common_v1_IDOptions) => Promise<DeploymentModel>;
  
  // Calculate the total size of a deployment with given arguments.
  // Required permissions:
  // - none
  CalculateDeploymentSize: (req: CalculateDeploymentSizeRequest) => Promise<DeploymentSize>;
  
  // Recommend a deployment size, for a oneshard or sharded deployments, using the
  // given input values.
  // Required permissions:
  // - none
  RecommendDeploymentSize: (req: DeploymentSizeRequest) => Promise<DeploymentSizeRecommendation>;
  
  // Fetch instructions for connecting drivers to the deployment identified by the given id.
  // Required permissions:
  // - data.deployment.get on the deployment identified by the given ID
  GetConnectDriverInstructions: (req: arangodb_cloud_common_v1_IDOptions) => Promise<ConnectDriverInstructions>;
  
  // Fetch instructions for importing data into the deployment identified by the given id.
  // Required permissions:
  // - data.deployment.get on the deployment identified by the given ID
  GetImportDataInstructions: (req: arangodb_cloud_common_v1_IDOptions) => Promise<ImportDataInstructions>;
  
  // Calculate the price of a deployment of given settings.
  // Required permissions:
  // - data.deploymentprice.calculate
  CalculateDeploymentPrice: (req: DeploymentPriceRequest) => Promise<DeploymentPrice>;
  
  // Get the features that will be available to a deployment in the given context.
  // Required permissions:
  // - data.deploymentfeatures.get on the project that is given in the request.
  GetDeploymentFeatures: (req: DeploymentFeaturesRequest) => Promise<DeploymentFeatures>;
  
  // Pauses a deployment indentified by the given ID.
  // When PauseDeployment is invoked on a deployment that is not allowed to pause or has is_paused set, an PreconditionFailed error is returned.
  // Required permissions:
  // - data.deployment.pause on the deployment
  PauseDeployment: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Resumes a paused deployment identified by the given id.
  // When ResumeDeployment is invoked on a deployment that has is_paused not set, an PreconditionFailed error is returned.
  // Required permissions:
  // - data.deployment.resume on the deployment
  ResumeDeployment: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Rotate a server for a deployment.
  // This causes the server to restart after condition are deemed safe for a restart.
  // Required permissions:
  // - data.deployment.rotate-server on the deployment
  RotateDeploymentServer: (req: RotateDeploymentServerRequest) => Promise<void>;
  
  // Create a test database and user for a deployment. Returns the output containing the created
  // database name, password, username, host and port.
  // Required permissions:
  // - data.deployment.create-test-database on the deployment
  CreateTestDatabase: (req: CreateTestDatabaseRequest) => Promise<CreateTestDatabaseResponse>;
  
  // RebalanceDeploymentShards rebalances shards for deployment across the DB servers.
  // Prerelease: This function is not yet available in production.
  // Required permissions:
  // - data.deployment.rebalance-shards on the deployment
  RebalanceDeploymentShards: (req: RebalanceDeploymentShardsRequest) => Promise<void>;
  
  // Lists disk performances that match all of the given filters.
  // Required permissions:
  // - data.diskperformance.list on the deployment (if deployment ID is provided)
  // - data.diskperformance.list on the organization (if organization ID is provided, but deployment ID is not)
  // - None, authenticated only (if no deployment ID is provided)
  ListDiskPerformances: (req: ListDiskPerformancesRequest) => Promise<DiskPerformanceList>;
  
  // Get the disk performance for the requested disk performance ID.
  // Required permissions:
  // - None, authenticated only
  GetDiskPerformance: (req: GetDiskPerformanceRequest) => Promise<DiskPerformance>;
}

// DataService is the API used to configure data objects.
export class DataService implements IDataService {
  // Get the current API version of this service.
  // Required permissions:
  // - None
  async GetAPIVersion(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_common_v1_Version> {
    const path = `/api/data/v1/api-version`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch all deployments in the project identified by the given context ID.
  // Required permissions:
  // - data.deployment.list on the project identified by the given context ID
  async ListDeployments(req: arangodb_cloud_common_v1_ListOptions): Promise<DeploymentList> {
    const path = `/api/data/v1/projects/${encodeURIComponent(req.context_id || '')}/deployments`;
    const url = path + api.queryString(req, [`context_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all deployments in the organization identified by the given filter.
  // Only the deployments with sufficient permissions are returned.
  // If the caller has no such permission on any of the deployments (in the organization) an empty list is returned, not an error.
  // Required permissions:
  // - None (authenticated only)
  // - data.deployment.list on the filtered deployment(s)
  async ListDeploymentsByFilter(req: ListDeploymentsByFilterRequest): Promise<DeploymentList> {
    const path = `/api/data/v1/organizations/${encodeURIComponent(req.organization_id || '')}/deployments`;
    const url = path + api.queryString(req, [`organization_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch a deployment by its id.
  // Required permissions:
  // - data.deployment.get on the deployment identified by the given ID
  async GetDeployment(req: arangodb_cloud_common_v1_IDOptions): Promise<Deployment> {
    const path = `/api/data/v1/deployments/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all updates (spec and/or status) of the deployment that matches the given request.
  // Required permissions:
  // - data.deployment.get on the deployment identified by the given ID
  async GetDeploymentUpdates(req: arangodb_cloud_common_v1_IDOptions, cb: (obj: IStreamMessage<Deployment>) => void): Promise<IServerStream> {
    const url = `/api/data/v1/streaming/deployment`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // Create a new deployment
  // Required permissions:
  // - data.deployment.create on the project that owns the deployment
  // - network.privateendpointservice.create on the project that owns the deployment (if private_endpoint field is set)
  // Note that deployment.status & deployment.expiration are ignored
  // in this request.
  async CreateDeployment(req: Deployment): Promise<Deployment> {
    const url = `/api/data/v1/project/${encodeURIComponent(req.project_id || '')}/deployments`;
    return api.post(url, req);
  }
  
  // Update a deployment
  // Required permissions:
  // - data.deployment.update on the deployment
  // Note that deployment.status & deployment.expiration are ignored
  // in this request.
  async UpdateDeployment(req: Deployment): Promise<Deployment> {
    const url = `/api/data/v1/deployments/${encodeURIComponent(req.id || '')}`;
    return api.patch(url, req);
  }
  
  // Update the setting for scheduled root password rotation
  // Required permissions:
  // - data.deployment.update-scheduled-root-password-rotation
  async UpdateDeploymentScheduledRootPasswordRotation(req: UpdateDeploymentScheduledRootPasswordRotationRequest): Promise<void> {
    const url = `/api/data/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/scheduled-rootpassword-rotation`;
    return api.put(url, req);
  }
  
  // Delete a deployment
  // Note that deployments are initially only marked for deletion.
  // Once all their resources are removed the deployment itself is removed.
  // Required permissions:
  // - data.deployment.delete on the deployment
  async DeleteDeployment(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/api/data/v1/deployments/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Fetch credentials for accessing deployment by its id.
  // Required permissions:
  // - data.deployment.get on the deployment identified by the given ID
  // - data.deploymentcredentials.get on the deployment identified by the given ID
  async GetDeploymentCredentials(req: DeploymentCredentialsRequest): Promise<DeploymentCredentials> {
    const path = `/api/data/v1/deploymentcredentials/${encodeURIComponent(req.deployment_id || '')}`;
    const url = path + api.queryString(req, [`deployment_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all ArangoDB versions that are available for deployments.
  // Required permissions:
  // - None
  async ListVersions(req: ListVersionsRequest): Promise<VersionList> {
    const path = `/api/data/v1/versions`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch the default ArangoDB version for new deployment.
  // Required permissions:
  // - None
  async GetDefaultVersion(req?: arangodb_cloud_common_v1_Empty): Promise<Version> {
    const path = `/api/data/v1/versions/default`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch the ArangoDB version by its id (semver).
  // Required permissions:
  // - None
  async GetVersion(req: arangodb_cloud_common_v1_IDOptions): Promise<Version> {
    const path = `/api/data/v1/versions/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch the limits for server specifications for deployments
  // owned by the given projected, created in the given region.
  // Required permissions:
  // - data.limits.get on the requested project
  // - data.deployment.get on the specified deployment (if deployment_id is set)
  // This method is deprecated and will be remove in the very near future, do not use this method anymore
  async GetServersSpecLimits(req: ServersSpecLimitsRequest): Promise<ServersSpecLimits> {
    const path = `/api/data/v1/projects/${encodeURIComponent(req.project_id || '')}/regions/${encodeURIComponent(req.region_id || '')}/limits`;
    const url = path + api.queryString(req, [`project_id`, `region_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch the node sizes available for deployments
  // owned by the project with given ID, created in the given region with given ID.
  // If project ID "all" is used, then all node sizes for the region with given
  // ID are returned.
  // Required permissions:
  // - data.nodesize.list on the requested deployment (if deployment ID is set)
  // - data.nodesize.list on the requested project (if project ID does not equal "all")
  // - data.nodesize.list on the requested organization (if organization ID is set)
  // - None if project ID does equals "all"
  async ListNodeSizes(req: NodeSizesRequest): Promise<NodeSizeList> {
    const path = `/api/data/v1/projects/${encodeURIComponent(req.project_id || '')}/regions/${encodeURIComponent(req.region_id || '')}/nodesizes`;
    const url = path + api.queryString(req, [`project_id`, `region_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch the models available for deployments owned by the project with given ID.
  // Required permissions:
  // - data.deploymentmodel.list on the requested project
  async ListDeploymentModels(req: ListDeploymentModelsRequest): Promise<DeploymentModelList> {
    const path = `/api/data/v1/projects/${encodeURIComponent(req.project_id || '')}/deploymentmodels`;
    const url = path + api.queryString(req, [`project_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch the CPU sizes available for deployments owned by the project with given ID.
  // Required permissions:
  // - data.cpusize.list on the requested project
  async ListCPUSizes(req: ListCPUSizesRequest): Promise<CPUSizeList> {
    const path = `/api/data/v1/projects/${encodeURIComponent(req.project_id || '')}/cpusizes`;
    const url = path + api.queryString(req, [`project_id`]);
    return api.get(url, undefined);
  }
  
  // Get the deployment model identified by the provided ID.
  // Required permissions:
  // - None (authenticated only)
  async GetDeploymentModel(req: arangodb_cloud_common_v1_IDOptions): Promise<DeploymentModel> {
    const path = `/api/data/v1/deploymentmodel/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Calculate the total size of a deployment with given arguments.
  // Required permissions:
  // - none
  async CalculateDeploymentSize(req: CalculateDeploymentSizeRequest): Promise<DeploymentSize> {
    const path = `/api/data/v1/deployment-size/calculate`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Recommend a deployment size, for a oneshard or sharded deployments, using the
  // given input values.
  // Required permissions:
  // - none
  async RecommendDeploymentSize(req: DeploymentSizeRequest): Promise<DeploymentSizeRecommendation> {
    const path = `/api/data/v1/deployment-size/recommend`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch instructions for connecting drivers to the deployment identified by the given id.
  // Required permissions:
  // - data.deployment.get on the deployment identified by the given ID
  async GetConnectDriverInstructions(req: arangodb_cloud_common_v1_IDOptions): Promise<ConnectDriverInstructions> {
    const path = `/api/data/v1/deployments/${encodeURIComponent(req.id || '')}/connect-driver-instructions`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch instructions for importing data into the deployment identified by the given id.
  // Required permissions:
  // - data.deployment.get on the deployment identified by the given ID
  async GetImportDataInstructions(req: arangodb_cloud_common_v1_IDOptions): Promise<ImportDataInstructions> {
    const path = `/api/data/v1/deployments/${encodeURIComponent(req.id || '')}/import-data-instructions`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Calculate the price of a deployment of given settings.
  // Required permissions:
  // - data.deploymentprice.calculate
  async CalculateDeploymentPrice(req: DeploymentPriceRequest): Promise<DeploymentPrice> {
    const path = `/api/data/v1/deployment-price/calculate`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Get the features that will be available to a deployment in the given context.
  // Required permissions:
  // - data.deploymentfeatures.get on the project that is given in the request.
  async GetDeploymentFeatures(req: DeploymentFeaturesRequest): Promise<DeploymentFeatures> {
    const url = `/api/data/v1/deployment-features`;
    return api.post(url, req);
  }
  
  // Pauses a deployment indentified by the given ID.
  // When PauseDeployment is invoked on a deployment that is not allowed to pause or has is_paused set, an PreconditionFailed error is returned.
  // Required permissions:
  // - data.deployment.pause on the deployment
  async PauseDeployment(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/api/data/v1/deployments/${encodeURIComponent(req.id || '')}/pause`;
    const url = path + api.queryString(req, [`id`]);
    return api.post(url, undefined);
  }
  
  // Resumes a paused deployment identified by the given id.
  // When ResumeDeployment is invoked on a deployment that has is_paused not set, an PreconditionFailed error is returned.
  // Required permissions:
  // - data.deployment.resume on the deployment
  async ResumeDeployment(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/api/data/v1/deployments/${encodeURIComponent(req.id || '')}/resume`;
    const url = path + api.queryString(req, [`id`]);
    return api.post(url, undefined);
  }
  
  // Rotate a server for a deployment.
  // This causes the server to restart after condition are deemed safe for a restart.
  // Required permissions:
  // - data.deployment.rotate-server on the deployment
  async RotateDeploymentServer(req: RotateDeploymentServerRequest): Promise<void> {
    const path = `/api/data/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/rotate/${encodeURIComponent(req.server_id || '')}`;
    const url = path + api.queryString(req, [`deployment_id`, `server_id`]);
    return api.post(url, undefined);
  }
  
  // Create a test database and user for a deployment. Returns the output containing the created
  // database name, password, username, host and port.
  // Required permissions:
  // - data.deployment.create-test-database on the deployment
  async CreateTestDatabase(req: CreateTestDatabaseRequest): Promise<CreateTestDatabaseResponse> {
    const path = `/api/data/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/create-test-database`;
    const url = path + api.queryString(req, [`deployment_id`]);
    return api.post(url, undefined);
  }
  
  // RebalanceDeploymentShards rebalances shards for deployment across the DB servers.
  // Prerelease: This function is not yet available in production.
  // Required permissions:
  // - data.deployment.rebalance-shards on the deployment
  async RebalanceDeploymentShards(req: RebalanceDeploymentShardsRequest): Promise<void> {
    const path = `/api/data/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/rebalance-shards`;
    const url = path + api.queryString(req, [`deployment_id`]);
    return api.post(url, undefined);
  }
  
  // Lists disk performances that match all of the given filters.
  // Required permissions:
  // - data.diskperformance.list on the deployment (if deployment ID is provided)
  // - data.diskperformance.list on the organization (if organization ID is provided, but deployment ID is not)
  // - None, authenticated only (if no deployment ID is provided)
  async ListDiskPerformances(req: ListDiskPerformancesRequest): Promise<DiskPerformanceList> {
    const url = `/api/data/v1/disk-performances`;
    return api.post(url, req);
  }
  
  // Get the disk performance for the requested disk performance ID.
  // Required permissions:
  // - None, authenticated only
  async GetDiskPerformance(req: GetDiskPerformanceRequest): Promise<DiskPerformance> {
    const url = `/api/data/v1/disk-performance/${encodeURIComponent(req.disk_performance_id || '')}`;
    return api.post(url, req);
  }
}
