//
// This file is AUTO-GENERATED by protoc-gen-ts.
// Do not modify it manually.
///
import api from '../../api'
import * as googleTypes from '../../googleTypes'
import { Budget as arangodb_cloud_common_v1_Budget } from '../../common/v1/common'
import { Empty as arangodb_cloud_common_v1_Empty } from '../../common/v1/common'
import { IDOptions as arangodb_cloud_common_v1_IDOptions } from '../../common/v1/common'
import { ListOptions as arangodb_cloud_common_v1_ListOptions } from '../../common/v1/common'
import { Version as arangodb_cloud_common_v1_Version } from '../../common/v1/common'
import { User as arangodb_cloud_iam_v1_User } from '../../iam/v1/iam'

// File: resourcemanager/v1/resourcemanager.proto
// Package: arangodb.cloud.resourcemanager.v1

// Specify which authentication providers are allowed for use
// in a specific Organization.
// For each field applies:
// - If set, users can access the organization when using that authentication provider.
// - If not set, users will be denied access to the organizatin when using that authentication provider.
export interface AuthenticationProviders {
  // Control access to the organization for users using then username/password authentication provider.
  // boolean
  enable_username_password?: boolean;
  
  // Control access to the organization for users using then Google authentication provider.
  // boolean
  enable_google?: boolean;
  
  // Control access to the organization for users using then Github authentication provider.
  // boolean
  enable_github?: boolean;
  
  // Control access to the organization for users using then Microsoft authentication provider.
  // boolean
  enable_microsoft?: boolean;
  
  // Control access to the organization for users using then SSO authentication provider.
  // boolean
  enable_sso?: boolean;
}
export interface DataProcessingAddendum {
  // Identifier of this version of the DPA
  // string
  id?: string;
  
  // Content of DPA in markdown format
  // string
  content?: string;
  
  // Creation date of this version of the DPA.
  // googleTypes.Timestamp
  created_at?: googleTypes.Timestamp;
}

// Specify restrictions related to email domain names of user accounts that are allowed to
// access resources of a specific Organization.
export interface DomainRestrictions {
  // If this list is non-empty, only users that have an email address in one
  // of the given domains will be granted access to the resources in the Organization.
  // string
  allowed_domains?: string[];
}

// An Event represents something that happened to an organization
// in the ArangoDB Managed service.
export interface Event {
  // System identifier of the event.
  // This is a read-only value.
  // string
  id?: string;
  
  // URL of this resource
  // This is a read-only value and cannot be initialized.
  // string
  url?: string;
  
  // Identifier of the organization that owns this event.
  // This is a read-only value.
  // string
  organization_id?: string;
  
  // Identifier of the subject of this event.
  // This is a read-only value.
  // If the subject of this event is an organization,
  // this value is a duplicate of organization_id.
  // string
  subject_id?: string;
  
  // Type of the event.
  // string
  type?: string;
  
  // Payload of the event.
  // The fields used in the payload are specific
  // to the type of event.
  // Event_PayloadEntry
  payload?: { [key: string]: string };
  
  // The creation timestamp of the event
  // googleTypes.Timestamp
  created_at?: googleTypes.Timestamp;
  
  // URL of the subject of this event.
  // This is a read-only value.
  // string
  subject_url?: string;
  
  // If set, this event is not persisted.
  // This is a read-only value.
  // boolean
  volatile?: boolean;
  
  // Identifier of the user that has created this event.
  // This is a read-only value that can be empty.
  // string
  created_by_id?: string;
  
  // Optional human readable reason for the event.
  // This is a read-only value that can be empty.
  // string
  reason?: string;
  
  // If set, this event reflects only a status update of an entity.
  // This is a read-only value.
  // boolean
  status_only?: boolean;
  
  // The application/tool which created this event.
  // This information is collected by inspecting headers:
  // First the x-arango-graph-user-agent will be tried, if this isn't set, the first service name from oasis-trace will be used, otherwise an empty string.
  // This is a read-only value that can be empty.
  // string
  created_with?: string;
}
export interface Event_PayloadEntry {
  // string
  key?: string;
  
  // string
  value?: string;
}

// List of Events.
export interface EventList {
  // Event
  items?: Event[];
}

// Request arguments for IsMemberOfOrganization.
export interface IsMemberOfOrganizationRequest {
  // Identifier of the user
  // string
  user_id?: string;
  
  // Identifier of the organization
  // string
  organization_id?: string;
}

// Response for IsMemberOfOrganization.
export interface IsMemberOfOrganizationResponse {
  // Set if the requested user is a member of the requested organization.
  // boolean
  member?: boolean;
  
  // Set if the requested user is an owner of the requested organization.
  // boolean
  owner?: boolean;
}

// Options for ListEvents
export interface ListEventOptions {
  // Standard list options
  // arangodb.cloud.common.v1.ListOptions
  options?: arangodb_cloud_common_v1_ListOptions;
  
  // If set, filter on the subject_id of event
  // string
  subject_ids?: string[];
  
  // If set, filter on the type of event
  // string
  types?: string[];
  
  // If set, filter of events created after this timestamp
  // googleTypes.Timestamp
  created_after?: googleTypes.Timestamp;
  
  // If set, filter of events created before this timestamp
  // googleTypes.Timestamp
  created_before?: googleTypes.Timestamp;
  
  // Sort descending (new to old) on created_at field (default is ascending).
  // boolean
  sort_descending?: boolean;
}

// Request arguments for ListXyzQuotas
export interface ListQuotasRequest {
  // Common list options
  // arangodb.cloud.common.v1.ListOptions
  options?: arangodb_cloud_common_v1_ListOptions;
  
  // If set, limit the returned list of quota's to these kinds.
  // string
  kinds?: string[];
}

// Member of an organization.
// A member is always a user.
export interface Member {
  // Identifier of the user
  // string
  user_id?: string;
  
  // Set if this user is owner of the organization
  // boolean
  owner?: boolean;
  
  // The user.
  // arangodb.cloud.iam.v1.User
  user?: arangodb_cloud_iam_v1_User;
}

// List of Members.
export interface MemberList {
  // Member
  items?: Member[];
}

// Define the Notification details
export interface Notification {
  // String representation of the Notification
  // string
  notification?: string;
  
  // Notification Severity
  // NotificationSeverity
  severity?: NotificationSeverity;
  
  // The timestamp of when the notification has been created.
  // googleTypes.Timestamp
  created_at?: googleTypes.Timestamp;
  
  // The timestamp of when the notification has been updated.
  // googleTypes.Timestamp
  updated_at?: googleTypes.Timestamp;
  
  // The timestamp of when the notification expires.
  // googleTypes.Timestamp
  expires_at?: googleTypes.Timestamp;
}

// An Organization is represents a real world organization such as a company.
export interface Organization {
  // System identifier of the organization.
  // This is a read-only value.
  // string
  id?: string;
  
  // URL of this resource
  // This is a read-only value and cannot be initialized.
  // string
  url?: string;
  
  // Name of the organization
  // string
  name?: string;
  
  // Description of the organization
  // string
  description?: string;
  
  // Set when this organization is deleted.
  // This is a read-only value.
  // boolean
  is_deleted?: boolean;
  
  // The creation timestamp of the organization
  // googleTypes.Timestamp
  created_at?: googleTypes.Timestamp;
  
  // The deletion timestamp of the organization
  // googleTypes.Timestamp
  deleted_at?: googleTypes.Timestamp;
  
  // Tier used for this organization.
  // This is a read-only value and cannot be initialized.
  // Tier
  tier?: Tier;
  
  // Total number of deployments created in this organization throughout
  // its entire lifetime per tier-id.
  // map: tier-id -> count
  // This is a read-only value.
  // Organization_TotalDeploymentsEntry
  total_deployments?: { [key: string]: number };
  
  // If set, all projects in this organization are allowed to use deployments using the flexible model.
  // This is a read-only value.
  // boolean
  is_flexible_deployments_enabled?: boolean;
  
  // If set, this organization is allowed to use custom images for ArangoDB deployments.
  // This is a read-only value.
  // boolean
  is_allowed_to_use_custom_images?: boolean;
  
  // If set, this organization is allowed to use IAMProviders on their deployments.
  // This is a read-only value.
  // boolean
  is_allowed_to_use_iamproviders?: boolean;
  
  // If set, this organization cannot be deleted.
  // To delete, first update the with locked set to false.
  // boolean
  locked?: boolean;
  
  // If set, this organization cannot create deployments without prepaid deployments.
  // This is a read-only value.
  // boolean
  requires_prepaid_deployments?: boolean;
  
  // If set, configures which authentication providers can/cannot be used for this organization.
  // If not set, all providers are enabled by default.
  // AuthenticationProviders
  authentication_providers?: AuthenticationProviders;
  
  // If set, configure restrictions on the email domains used by user accounts to access this organization.
  // If not set, there is no restriction of domains for user accounts.
  // DomainRestrictions
  email_domain_restrictions?: DomainRestrictions;
  
  // If set, this organization is allowed to manage IAM identities (like users) via SCIM
  // This is a read-only value.
  // boolean
  is_allowed_to_use_scim?: boolean;
  
  // Defines notifications attached to the Organization
  // Organization_NotificationsEntry
  notifications?: { [key: string]: Notification };
}
export interface Organization_NotificationsEntry {
  // string
  key?: string;
  
  // Notification
  value?: Notification;
}
export interface Organization_TotalDeploymentsEntry {
  // string
  key?: string;
  
  // number
  value?: number;
}

// An OrganizationInvite represents an invite for a human to join an
// organization.
export interface OrganizationInvite {
  // System identifier of the invite.
  // This is a read-only value.
  // string
  id?: string;
  
  // URL of this resource
  // This is a read-only value and cannot be initialized.
  // string
  url?: string;
  
  // Identifier of the organization that the human is invited to join.
  // This is a read-only value.
  // string
  organization_id?: string;
  
  // Email address of the human who is invited.
  // string
  email?: string;
  
  // If set, the invitee accepted the invite.
  // This is a read-only value.
  // boolean
  accepted?: boolean;
  
  // If set, the invitee rejected the invite.
  // This is a read-only value.
  // boolean
  rejected?: boolean;
  
  // The creation timestamp of the invite
  // This is a read-only value.
  // googleTypes.Timestamp
  created_at?: googleTypes.Timestamp;
  
  // The acceptance timestamp of the invite
  // This is a read-only value.
  // googleTypes.Timestamp
  accepted_at?: googleTypes.Timestamp;
  
  // The rejection timestamp of the invite
  // This is a read-only value.
  // googleTypes.Timestamp
  rejected_at?: googleTypes.Timestamp;
  
  // Identifier of the user that accepted or rejected this invite.
  // This is a read-only value.
  // string
  user_id?: string;
  
  // Identifier of the user that created this invite.
  // string
  created_by_id?: string;
  
  // Identifier of the organization that the human is invited to join.
  // This is a read-only value.
  // string
  organization_name?: string;
  
  // Name of the user that created this invite.
  // This is a read-only value.
  // string
  created_by_name?: string;
}

// List of OrganizationInvites.
export interface OrganizationInviteList {
  // OrganizationInvite
  items?: OrganizationInvite[];
}

// List of organizations.
export interface OrganizationList {
  // Actual organizations
  // Organization
  items?: Organization[];
  
  // Budget for organizations (owned by the caller)
  // arangodb.cloud.common.v1.Budget
  budget?: arangodb_cloud_common_v1_Budget;
}

// Request arguments for Add/DeleteOrganizationMembers.
export interface OrganizationMembersRequest {
  // Identifier of the organization to add/remove a user from
  // string
  organization_id?: string;
  
  // Users to add/remove.
  // For every user, an owner flag is provided as well.
  // If you add an existing user, the owner flag or the add request
  // will overwrite the value of the existing owner flag.
  // MemberList
  members?: MemberList;
}

// A Project is represents a unit within an organization such as a department.
export interface Project {
  // System identifier of the project.
  // This is a read-only value.
  // It can be set when creating the project.
  // string
  id?: string;
  
  // URL of this resource
  // This is a read-only value and cannot be initialized.
  // string
  url?: string;
  
  // Name of the project
  // string
  name?: string;
  
  // Description of the project
  // string
  description?: string;
  
  // Identifier of the organization that owns this project.
  // This is a read-only value.
  // string
  organization_id?: string;
  
  // Set when this project is deleted
  // boolean
  is_deleted?: boolean;
  
  // The creation timestamp of the project
  // googleTypes.Timestamp
  created_at?: googleTypes.Timestamp;
  
  // The deletion timestamp of the project
  // googleTypes.Timestamp
  deleted_at?: googleTypes.Timestamp;
  
  // If set, this project is allowed to use deployments using the flexible model.
  // boolean
  is_flexible_deployments_enabled?: boolean;
  
  // If set, this project cannot be deleted.
  // To delete, first update the with locked set to false.
  // boolean
  locked?: boolean;
}

// List of Projects.
export interface ProjectList {
  // Resulting projects
  // Project
  items?: Project[];
  
  // Budget for projects
  // arangodb.cloud.common.v1.Budget
  budget?: arangodb_cloud_common_v1_Budget;
}

// Quota limit
export interface Quota {
  // Kind of quota
  // string
  kind?: string;
  
  // Human readable description of the quota
  // string
  description?: string;
  
  // Current limit of the quota.
  // A value of 0 means unlimited.
  // number
  limit?: number;
}

// Description of a kind of quota's
export interface QuotaDescription {
  // Kind of the quota
  // string
  kind?: string;
  
  // Human readable description
  // string
  description?: string;
  
  // If set, this kind of quota is valid at organization level
  // boolean
  for_organizations?: boolean;
  
  // If set, this kind of quota is valid at project level
  // boolean
  for_projects?: boolean;
}

// List of QuotaDescription's
export interface QuotaDescriptionList {
  // QuotaDescription
  items?: QuotaDescription[];
}

// List of Quota's
export interface QuotaList {
  // Quota
  items?: Quota[];
}
export interface TermsAndConditions {
  // Identifier of this version of the terms & conditions
  // string
  id?: string;
  
  // Content of terms & conditions in markdown format
  // string
  content?: string;
  
  // Creation date of this version of the terms & conditions.
  // googleTypes.Timestamp
  created_at?: googleTypes.Timestamp;
}

// Tier of an organization.
export interface Tier {
  // Identifier of the tier.
  // This is a read-only value and cannot be initialized.
  // string
  id?: string;
  
  // Human readable name of the tier.
  // This is a read-only value and cannot be initialized.
  // string
  name?: string;
  
  // If set the tier has support plans.
  // This is a read-only value and cannot be initialized.
  // boolean
  has_support_plans?: boolean;
  
  // If set the tier has backup uploads.
  // This is a read-only value and cannot be initialized.
  // boolean
  has_backup_uploads?: boolean;
  
  // If set, the tier requires that new deployments accept the
  // current terms & conditions.
  // This is a read-only value and cannot be initialized.
  // boolean
  requires_terms_and_conditions?: boolean;
  
  // If set, this tier allows the user of support severity high.
  // boolean
  has_support_severity_high?: boolean;
  
  // If set, this tier allows the user of support severity critical.
  // boolean
  has_support_severity_critical?: boolean;
  
  // If set, this tier has the auditlog feature.
  // This is a read-only value and cannot be initialized.
  // boolean
  has_auditlog_feature?: boolean;
  
  // If set, this tier supports the auditlog destination 'cloud'.
  // This fields is only relevant if has_auditlog_feature is set.
  // This is a read-only value and cannot be initialized.
  // boolean
  has_auditlog_destination_cloud?: boolean;
  
  // If set, this tier supports the auditlog destination 'https-post'.
  // This fields is only relevant if has_auditlog_feature is set.
  // This is a read-only value and cannot be initialized.
  // boolean
  has_auditlog_destination_https_post?: boolean;
  
  // If set, this tier supports private endpoints.
  // This is a read-only value and cannot be initialized.
  // boolean
  has_private_endpoints?: boolean;
  
  // If set the tier has multi region backup uploads.
  // This is a read-only value and cannot be initialized.
  // boolean
  has_multi_region_backup_uploads?: boolean;
}

// NotificationSeverity keeps possible severities for notifications
export enum NotificationSeverity {
  // Defines Info level Notification Severity
  NOTIFICATION_SEVERITY_INFO = 0,
  
  // Defines Warning level Notification Severity
  NOTIFICATION_SEVERITY_WARNING = 1,
  
  // Defines Critical level Notification Severity
  NOTIFICATION_SEVERITY_CRITICAL = 2,
}

// ResourceManagerService is the API used to configure basic resource objects.
export interface IResourceManagerService {
  // Get the current API version of this service.
  // Required permissions:
  // - None
  GetAPIVersion: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_common_v1_Version>;
  
  // Fetch all organizations that the authenticated user is a member of.
  // Required permissions:
  // - None
  ListOrganizations: (req: arangodb_cloud_common_v1_ListOptions) => Promise<OrganizationList>;
  
  // Fetch an organization by its id.
  // The authenticated user must be a member of the organization.
  // Required permissions:
  // - None
  GetOrganization: (req: arangodb_cloud_common_v1_IDOptions) => Promise<Organization>;
  
  // Create a new organization
  // Required permissions:
  // - None
  CreateOrganization: (req: Organization) => Promise<Organization>;
  
  // Update an organization
  // Required permissions:
  // - resourcemanager.organization.update on the organization
  UpdateOrganization: (req: Organization) => Promise<Organization>;
  
  // Delete an organization
  // Note that organization are never really removed.
  // Instead their is_deleted field is set to true.
  // Required permissions:
  // - resourcemanager.organization.delete on the organization
  DeleteOrganization: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Get a list of members of the organization identified by the given context ID.
  // Required permissions:
  // - resourcemanager.organization.get on the organization
  ListOrganizationMembers: (req: arangodb_cloud_common_v1_ListOptions) => Promise<MemberList>;
  
  // Add one or more members to an organization.
  // If there are members (in the request arguments) that are already member of the
  // organization an AlreadyExists error is returned.
  // Required permissions:
  // - resourcemanager.organization.update on the organization
  AddOrganizationMembers: (req: OrganizationMembersRequest) => Promise<void>;
  
  // Update the ownership flag of one or more members of an organization.
  // If there are members (in the request arguments) that are not yet member of
  // the organization, an InvalidArgument error is returned.
  // If the request would result in the last owner no longer being an owner,
  // an InvalidArgument error is returned.
  // Required permissions:
  // - resourcemanager.organization.update on the organization
  UpdateOrganizationMembers: (req: OrganizationMembersRequest) => Promise<void>;
  
  // Remove one or more members from an organization.
  // If the request would result in the last owner being removed as member
  // of the organization, an InvalidArgument error is returned.
  // Required permissions:
  // - resourcemanager.organization.update on the organization
  DeleteOrganizationMembers: (req: OrganizationMembersRequest) => Promise<void>;
  
  // Is the user identified by the given user ID a member
  // of the organization identified by the given organization ID.
  // Required permissions:
  // - resourcemanager.organization.get on the organization, unless the requested user is identical to the authenticated user.
  // Note that if the identified user or organization does not exist, no is returned.
  IsMemberOfOrganization: (req: IsMemberOfOrganizationRequest) => Promise<IsMemberOfOrganizationResponse>;
  
  // Get a list of quota values for the organization identified by the given context ID.
  // If a quota is not specified on organization level, a (potentially tier specific) default
  // value is returned.
  // Required permissions:
  // - resourcemanager.organization.get on the organization
  ListOrganizationQuotas: (req: ListQuotasRequest) => Promise<QuotaList>;
  
  // Fetch all projects in the organization identified by the given context ID.
  // The authenticated user must be a member of the organization identifier by the given context ID.
  // Required permissions:
  // - resourcemanager.project.list on the organization identified by the given context ID
  ListProjects: (req: arangodb_cloud_common_v1_ListOptions) => Promise<ProjectList>;
  
  // Fetch a project by its id.
  // The authenticated user must be a member of the organization that owns the project.
  // Required permissions:
  // - resourcemanager.project.get on the project identified by the given ID
  GetProject: (req: arangodb_cloud_common_v1_IDOptions) => Promise<Project>;
  
  // Create a new project
  // The authenticated user must be a member of the organization that owns the project.
  // Required permissions:
  // - resourcemanager.project.create on the organization that owns the project
  CreateProject: (req: Project) => Promise<Project>;
  
  // Update a project
  // The authenticated user must be a member of the organization that owns the project.
  // Required permissions:
  // - resourcemanager.project.update on the project
  UpdateProject: (req: Project) => Promise<Project>;
  
  // Delete a project
  // Note that project are initially only marked for deleted.
  // Once all their resources are removed the project itself is deleted
  // and cannot be restored.
  // The authenticated user must be a member of the organization that owns the project.
  // Required permissions:
  // - resourcemanager.project.delete on the project
  DeleteProject: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Get a list of quota values for the project identified by the given context ID.
  // If a quota is not specified on project level, a value from organization level
  // is returned.
  // Required permissions:
  // - resourcemanager.project.get on the project
  ListProjectQuotas: (req: ListQuotasRequest) => Promise<QuotaList>;
  
  // Fetch all events in the organization identified by the given context ID.
  // The authenticated user must be a member of the organization identifier by the given context ID.
  // Required permissions:
  // - resourcemanager.event.list on the organization identified by the given context ID
  // Note that this method uses a POST method because the list options contains a repeated field.
  ListEvents: (req: ListEventOptions) => Promise<EventList>;
  
  // Fetch all organization invites in the organization identified by the given context ID.
  // The authenticated user must be a member of the organization identifier by the given context ID.
  // Required permissions:
  // - resourcemanager.organization-invite.list on the invite.
  ListOrganizationInvites: (req: arangodb_cloud_common_v1_ListOptions) => Promise<OrganizationInviteList>;
  
  // Fetch all organization invites for the email address of the authenticated user.
  // Required permissions:
  // - None
  ListMyOrganizationInvites: (req: arangodb_cloud_common_v1_ListOptions) => Promise<OrganizationInviteList>;
  
  // Fetch an organization invite by its id.
  // The authenticated user must be a member of the organization that the invite is for.
  // Required permissions:
  // - resourcemanager.organization-invite.get on the invite.
  GetOrganizationInvite: (req: arangodb_cloud_common_v1_IDOptions) => Promise<OrganizationInvite>;
  
  // Create a new organization invite.
  // The authenticated user must be a member of the organization that the invite is for.
  // Required permissions:
  // - resourcemanager.organization-invite.create on the organization that the invite is for.
  CreateOrganizationInvite: (req: OrganizationInvite) => Promise<OrganizationInvite>;
  
  // Delete an organization invite
  // The authenticated user must be a member of the organization that the invite is for.
  // Required permissions:
  // - resourcemanager.organization-invite.delete on the invite
  DeleteOrganizationInvite: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Accept an organization invite
  // The authenticated user's email address must match the email address specified in
  // the invite.
  // Required permissions:
  // - None
  AcceptOrganizationInvite: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Reject an organization invite
  // The authenticated user's email address must match the email address specified in
  // the invite.
  // Required permissions:
  // - None
  RejectOrganizationInvite: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Fetch descriptions for all quota kinds know by the platform.
  // Required permissions:
  // - None
  ListQuotaDescriptions: (req: arangodb_cloud_common_v1_ListOptions) => Promise<QuotaDescriptionList>;
  
  // Fetch a specific version of the Terms & Conditions.
  // Required permissions:
  // - None
  GetTermsAndConditions: (req: arangodb_cloud_common_v1_IDOptions) => Promise<TermsAndConditions>;
  
  // Fetch the current version of the Terms & Conditions for the organization
  // identified by the given (optional) ID.
  // Required permissions:
  // - None If ID is empty.
  // - resourcemanager.organization.get If ID is not empty.
  GetCurrentTermsAndConditions: (req: arangodb_cloud_common_v1_IDOptions) => Promise<TermsAndConditions>;
  
  // Fetch a specific version of the Data Processing Addendum.
  // Required permissions:
  // - None
  GetDataProcessingAddendum: (req: arangodb_cloud_common_v1_IDOptions) => Promise<DataProcessingAddendum>;
  
  // Fetch the current version of the Data Processing Addendum for the organization
  // identified by the given (optional) ID.
  // Required permissions:
  // - None If ID is empty.
  // - resourcemanager.organization.get If ID is not empty.
  GetCurrentDataProcessingAddendum: (req: arangodb_cloud_common_v1_IDOptions) => Promise<DataProcessingAddendum>;
}

// ResourceManagerService is the API used to configure basic resource objects.
export class ResourceManagerService implements IResourceManagerService {
  // Get the current API version of this service.
  // Required permissions:
  // - None
  async GetAPIVersion(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_common_v1_Version> {
    const path = `/api/resourcemanager/v1/api-version`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch all organizations that the authenticated user is a member of.
  // Required permissions:
  // - None
  async ListOrganizations(req: arangodb_cloud_common_v1_ListOptions): Promise<OrganizationList> {
    const path = `/api/resourcemanager/v1/self/organizations`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch an organization by its id.
  // The authenticated user must be a member of the organization.
  // Required permissions:
  // - None
  async GetOrganization(req: arangodb_cloud_common_v1_IDOptions): Promise<Organization> {
    const path = `/api/resourcemanager/v1/organizations/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Create a new organization
  // Required permissions:
  // - None
  async CreateOrganization(req: Organization): Promise<Organization> {
    const url = `/api/resourcemanager/v1/organizations`;
    return api.post(url, req);
  }
  
  // Update an organization
  // Required permissions:
  // - resourcemanager.organization.update on the organization
  async UpdateOrganization(req: Organization): Promise<Organization> {
    const url = `/api/resourcemanager/v1/organizations/${encodeURIComponent(req.id || '')}`;
    return api.patch(url, req);
  }
  
  // Delete an organization
  // Note that organization are never really removed.
  // Instead their is_deleted field is set to true.
  // Required permissions:
  // - resourcemanager.organization.delete on the organization
  async DeleteOrganization(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/api/resourcemanager/v1/organizations/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Get a list of members of the organization identified by the given context ID.
  // Required permissions:
  // - resourcemanager.organization.get on the organization
  async ListOrganizationMembers(req: arangodb_cloud_common_v1_ListOptions): Promise<MemberList> {
    const path = `/api/resourcemanager/v1/organizations/${encodeURIComponent(req.context_id || '')}/members`;
    const url = path + api.queryString(req, [`context_id`]);
    return api.get(url, undefined);
  }
  
  // Add one or more members to an organization.
  // If there are members (in the request arguments) that are already member of the
  // organization an AlreadyExists error is returned.
  // Required permissions:
  // - resourcemanager.organization.update on the organization
  async AddOrganizationMembers(req: OrganizationMembersRequest): Promise<void> {
    const url = `/api/resourcemanager/v1/organizations/${encodeURIComponent(req.organization_id || '')}/members`;
    return api.post(url, req);
  }
  
  // Update the ownership flag of one or more members of an organization.
  // If there are members (in the request arguments) that are not yet member of
  // the organization, an InvalidArgument error is returned.
  // If the request would result in the last owner no longer being an owner,
  // an InvalidArgument error is returned.
  // Required permissions:
  // - resourcemanager.organization.update on the organization
  async UpdateOrganizationMembers(req: OrganizationMembersRequest): Promise<void> {
    const url = `/api/resourcemanager/v1/organizations/${encodeURIComponent(req.organization_id || '')}/members`;
    return api.patch(url, req);
  }
  
  // Remove one or more members from an organization.
  // If the request would result in the last owner being removed as member
  // of the organization, an InvalidArgument error is returned.
  // Required permissions:
  // - resourcemanager.organization.update on the organization
  async DeleteOrganizationMembers(req: OrganizationMembersRequest): Promise<void> {
    const url = `/api/resourcemanager/v1/organizations/${encodeURIComponent(req.organization_id || '')}/members`;
    return api.delete(url, req);
  }
  
  // Is the user identified by the given user ID a member
  // of the organization identified by the given organization ID.
  // Required permissions:
  // - resourcemanager.organization.get on the organization, unless the requested user is identical to the authenticated user.
  // Note that if the identified user or organization does not exist, no is returned.
  async IsMemberOfOrganization(req: IsMemberOfOrganizationRequest): Promise<IsMemberOfOrganizationResponse> {
    const path = `/api/resourcemanager/v1/organizations/${encodeURIComponent(req.organization_id || '')}/members/${encodeURIComponent(req.user_id || '')}`;
    const url = path + api.queryString(req, [`organization_id`, `user_id`]);
    return api.get(url, undefined);
  }
  
  // Get a list of quota values for the organization identified by the given context ID.
  // If a quota is not specified on organization level, a (potentially tier specific) default
  // value is returned.
  // Required permissions:
  // - resourcemanager.organization.get on the organization
  async ListOrganizationQuotas(req: ListQuotasRequest): Promise<QuotaList> {
    const path = `/api/resourcemanager/v1/organizations/${encodeURIComponent((req.options || {}).context_id || '')}/quotas`;
    const url = path + api.queryString(req, [`options.context_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all projects in the organization identified by the given context ID.
  // The authenticated user must be a member of the organization identifier by the given context ID.
  // Required permissions:
  // - resourcemanager.project.list on the organization identified by the given context ID
  async ListProjects(req: arangodb_cloud_common_v1_ListOptions): Promise<ProjectList> {
    const path = `/api/resourcemanager/v1/organizations/${encodeURIComponent(req.context_id || '')}/projects`;
    const url = path + api.queryString(req, [`context_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch a project by its id.
  // The authenticated user must be a member of the organization that owns the project.
  // Required permissions:
  // - resourcemanager.project.get on the project identified by the given ID
  async GetProject(req: arangodb_cloud_common_v1_IDOptions): Promise<Project> {
    const path = `/api/resourcemanager/v1/projects/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Create a new project
  // The authenticated user must be a member of the organization that owns the project.
  // Required permissions:
  // - resourcemanager.project.create on the organization that owns the project
  async CreateProject(req: Project): Promise<Project> {
    const url = `/api/resourcemanager/v1/organizations/${encodeURIComponent(req.organization_id || '')}/projects`;
    return api.post(url, req);
  }
  
  // Update a project
  // The authenticated user must be a member of the organization that owns the project.
  // Required permissions:
  // - resourcemanager.project.update on the project
  async UpdateProject(req: Project): Promise<Project> {
    const url = `/api/resourcemanager/v1/projects/${encodeURIComponent(req.id || '')}`;
    return api.patch(url, req);
  }
  
  // Delete a project
  // Note that project are initially only marked for deleted.
  // Once all their resources are removed the project itself is deleted
  // and cannot be restored.
  // The authenticated user must be a member of the organization that owns the project.
  // Required permissions:
  // - resourcemanager.project.delete on the project
  async DeleteProject(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/api/resourcemanager/v1/projects/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Get a list of quota values for the project identified by the given context ID.
  // If a quota is not specified on project level, a value from organization level
  // is returned.
  // Required permissions:
  // - resourcemanager.project.get on the project
  async ListProjectQuotas(req: ListQuotasRequest): Promise<QuotaList> {
    const path = `/api/resourcemanager/v1/projects/${encodeURIComponent((req.options || {}).context_id || '')}/quotas`;
    const url = path + api.queryString(req, [`options.context_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all events in the organization identified by the given context ID.
  // The authenticated user must be a member of the organization identifier by the given context ID.
  // Required permissions:
  // - resourcemanager.event.list on the organization identified by the given context ID
  // Note that this method uses a POST method because the list options contains a repeated field.
  async ListEvents(req: ListEventOptions): Promise<EventList> {
    const url = `/api/resourcemanager/v1/organizations/${encodeURIComponent((req.options || {}).context_id || '')}/events`;
    return api.post(url, req);
  }
  
  // Fetch all organization invites in the organization identified by the given context ID.
  // The authenticated user must be a member of the organization identifier by the given context ID.
  // Required permissions:
  // - resourcemanager.organization-invite.list on the invite.
  async ListOrganizationInvites(req: arangodb_cloud_common_v1_ListOptions): Promise<OrganizationInviteList> {
    const path = `/api/resourcemanager/v1/organizations/${encodeURIComponent(req.context_id || '')}/organization-invites`;
    const url = path + api.queryString(req, [`context_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all organization invites for the email address of the authenticated user.
  // Required permissions:
  // - None
  async ListMyOrganizationInvites(req: arangodb_cloud_common_v1_ListOptions): Promise<OrganizationInviteList> {
    const path = `/api/resourcemanager/v1/self/organization-invites`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch an organization invite by its id.
  // The authenticated user must be a member of the organization that the invite is for.
  // Required permissions:
  // - resourcemanager.organization-invite.get on the invite.
  async GetOrganizationInvite(req: arangodb_cloud_common_v1_IDOptions): Promise<OrganizationInvite> {
    const path = `/api/resourcemanager/v1/organization-invites/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Create a new organization invite.
  // The authenticated user must be a member of the organization that the invite is for.
  // Required permissions:
  // - resourcemanager.organization-invite.create on the organization that the invite is for.
  async CreateOrganizationInvite(req: OrganizationInvite): Promise<OrganizationInvite> {
    const url = `/api/resourcemanager/v1/organizations/${encodeURIComponent(req.organization_id || '')}/organization-invites`;
    return api.post(url, req);
  }
  
  // Delete an organization invite
  // The authenticated user must be a member of the organization that the invite is for.
  // Required permissions:
  // - resourcemanager.organization-invite.delete on the invite
  async DeleteOrganizationInvite(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/api/resourcemanager/v1/organization-invites/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Accept an organization invite
  // The authenticated user's email address must match the email address specified in
  // the invite.
  // Required permissions:
  // - None
  async AcceptOrganizationInvite(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/api/resourcemanager/v1/organization-invites/${encodeURIComponent(req.id || '')}/accept`;
    const url = path + api.queryString(req, [`id`]);
    return api.post(url, undefined);
  }
  
  // Reject an organization invite
  // The authenticated user's email address must match the email address specified in
  // the invite.
  // Required permissions:
  // - None
  async RejectOrganizationInvite(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/api/resourcemanager/v1/organization-invites/${encodeURIComponent(req.id || '')}/reject`;
    const url = path + api.queryString(req, [`id`]);
    return api.post(url, undefined);
  }
  
  // Fetch descriptions for all quota kinds know by the platform.
  // Required permissions:
  // - None
  async ListQuotaDescriptions(req: arangodb_cloud_common_v1_ListOptions): Promise<QuotaDescriptionList> {
    const path = `/api/resourcemanager/v1/quotas/descriptions`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch a specific version of the Terms & Conditions.
  // Required permissions:
  // - None
  async GetTermsAndConditions(req: arangodb_cloud_common_v1_IDOptions): Promise<TermsAndConditions> {
    const path = `/api/resourcemanager/v1/termsandconditions/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch the current version of the Terms & Conditions for the organization
  // identified by the given (optional) ID.
  // Required permissions:
  // - None If ID is empty.
  // - resourcemanager.organization.get If ID is not empty.
  async GetCurrentTermsAndConditions(req: arangodb_cloud_common_v1_IDOptions): Promise<TermsAndConditions> {
    const path = `/api/resourcemanager/v1/current-termsandconditions`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch a specific version of the Data Processing Addendum.
  // Required permissions:
  // - None
  async GetDataProcessingAddendum(req: arangodb_cloud_common_v1_IDOptions): Promise<DataProcessingAddendum> {
    const path = `/api/resourcemanager/v1/dpa/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch the current version of the Data Processing Addendum for the organization
  // identified by the given (optional) ID.
  // Required permissions:
  // - None If ID is empty.
  // - resourcemanager.organization.get If ID is not empty.
  async GetCurrentDataProcessingAddendum(req: arangodb_cloud_common_v1_IDOptions): Promise<DataProcessingAddendum> {
    const path = `/api/resourcemanager/v1/current-dpa`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
}
